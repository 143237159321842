import "./controllers"


// REACT
// REACT
// REACT
import React from 'react';
import { createRoot } from 'react-dom/client';


// ADMIN: Form Manager
import Forms from './components/admin/Forms';
document.addEventListener('DOMContentLoaded', () => {
  const formManagerContainer = document.getElementById('formManagerContainer');
  if (formManagerContainer) {
    const root = createRoot(formManagerContainer);
    root.render(<Forms />);
  }
});




// ODDS AND ENDS
// ODDS AND ENDS
// ODDS AND ENDS
function onSubmit(token) {
  document.getElementById("new_message").submit();
}

setTimeout(function() {
  var alertElements = document.querySelectorAll('.alert');
  alertElements.forEach(function(element) {
    element.style.transition = 'opacity 0.5s ease'; // Set transition for fading effect
    element.style.opacity = '0'; // Fade out the element

    // After the transition ends, remove the element or hide it completely
    element.addEventListener('transitionend', function() {
      element.style.display = 'none';
    });
  });
}, 5000); // 5 seconds delay



