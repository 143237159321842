import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Forms = () => {
  const [forms, setForms] = useState([]);
  const [newForm, setNewForm] = useState({ name: '', description: '', template_id: '', category: '', admin_notes: '' });
  const [editingForm, setEditingForm] = useState(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    const response = await axios.get('/api/v1/forms');
    setForms(response.data);
  };

  const handleDelete = async (formId) => {
    await axios.delete(`/api/v1/forms/${formId}`, {
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    });
    fetchForms();
  };

  const handleEdit = (form) => {
    setEditingForm(form);
    setShowEditModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    await axios.put(`/api/v1/forms/${editingForm.id}`, { form: editingForm }, {
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    });
    setEditingForm(null);
    setShowEditModal(false);
    fetchForms();
  };

  const handleNewFormChange = (e) => {
    const { name, value } = e.target;
    setNewForm({ ...newForm, [name]: value });
  };

  const handleNewFormSubmit = async (e) => {
    e.preventDefault();
    await axios.post('/api/v1/forms', { form: newForm }, {
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    });
    setNewForm({ name: '', description: '', template_id: '', category: '', admin_notes: '' });
    setShowNewModal(false);
    fetchForms();
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditingForm({ ...editingForm, [name]: value });
  };

  return (
    <div className="sck-container">
      <button className="btn mb-3" onClick={() => setShowNewModal(true)}>
        Add New Form
      </button>

      {/* Table for existing forms */}
      <table className="table table-bordered">
        <thead>
          <tr className="text-xs">
            <th>Name</th>
            <th>Description</th>
            <th>Template ID</th>
            <th>Category</th>
            <th>Admin Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {forms.map((form) => (
            <tr key={form.id}  className="text-2xs">
              <td>{form.name}</td>
              <td>{form.description}</td>
              <td>{form.template_id}</td>
              <td>{form.category}</td>
              <td>{form.admin_notes}</td>
              <td>
                <div className="text-xs inline-block mx-2" onClick={() => handleEdit(form)}>
                  <i className="fa-solid fa-pen"></i>
                </div>
                <div className="text-xs inline-block mx-2" onClick={() => handleDelete(form.id)}>
                  <i className="fa-solid fa-trash"></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* New Form Modal */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 ${
          showNewModal ? 'flex' : 'hidden'
        }`}
      >
        <div className="bg-white rounded-lg shadow-lg w-[90%] h-auto max-h-[90vh] my-12 md:max-w-3xl overflow-y-auto">
          <div className="flex justify-between items-center p-4 border-b border-gray-200">
            <h5 className="text-xl font-bold">Add New Form</h5>
            <button type="button" className="text-gray-500 hover:text-gray-800" onClick={() => setShowNewModal(false)}>
              &times;
            </button>
          </div>
          <div className="p-4">
            <form onSubmit={handleNewFormSubmit}>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={newForm.name}
                  onChange={handleNewFormChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  value={newForm.description}
                  onChange={handleNewFormChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label>Template ID</label>
                <input
                  type="text"
                  name="template_id"
                  className="form-control"
                  value={newForm.template_id}
                  onChange={handleNewFormChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Category</label>
                <select
                  name="category"
                  className="form-control"
                  value={newForm.category}
                  onChange={handleNewFormChange}
                >
                  <option value="">Select Category</option>
                  <option value="buying">Buying</option>
                  <option value="renting">Renting</option>
                  <option value="selling">Selling</option>
                  <option value="loans">Loans</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <label>Admin Notes</label>
                <textarea
                  name="admin_notes"
                  className="form-control"
                  value={newForm.admin_notes}
                  onChange={handleNewFormChange}
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Add Form
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Edit Form Modal */}
      {editingForm && (
        <div
          className={`fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 ${
            showEditModal ? 'flex' : 'hidden'
          }`}
        >
          <div className="bg-white rounded-lg shadow-lg w-[90%] h-auto max-h-[90vh] my-12 md:max-w-3xl overflow-y-auto">
            <div className="flex justify-between items-center p-4 border-b border-gray-200">
              <h5 className="text-xl font-bold">Edit Form</h5>
              <button type="button" className="text-gray-500 hover:text-gray-800" onClick={() => setShowEditModal(false)}>
                &times;
              </button>
            </div>
            <div className="p-4">
              <form onSubmit={handleUpdate}>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={editingForm.name}
                    onChange={handleEditFormChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    name="description"
                    className="form-control"
                    value={editingForm.description}
                    onChange={handleEditFormChange}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Template ID</label>
                  <input
                    type="text"
                    name="template_id"
                    className="form-control"
                    value={editingForm.template_id}
                    onChange={handleEditFormChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Category</label>
                  <select
                    name="category"
                    className="form-control"
                    value={editingForm.category}
                    onChange={handleEditFormChange}
                  >
                    <option value="">Select Category</option>
                    <option value="buying">Buying</option>
                    <option value="renting">Renting</option>
                    <option value="selling">Selling</option>
                    <option value="loans">Loans</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Admin Notes</label>
                  <textarea
                    name="admin_notes"
                    className="form-control"
                    value={editingForm.admin_notes}
                    onChange={handleEditFormChange}
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">
                  Update Form
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Forms;
